"use server";

import { getRequestEvent } from "solid-js/web";
import { getAllGifts, getGiftById, getUserProfile } from "../apis/server_apis";
import { GiftCardDetails } from "../types/pluto";
import { UserProfile } from "../types/user";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";

export type PlutoProfileRouteData = {
  paidGifts?: GiftCardDetails[];
  notPaidGifts?: GiftCardDetails[];
  userProfile?: UserProfile;
};

export const getPlutoProfileRouteData =
  async (): Promise<PlutoProfileRouteData> => {
    const requestEvent = getRequestEvent();
    let userProfile: UserProfile | undefined;
    let notPaidGifts: GiftCardDetails[] | undefined;
    let paidGifts: GiftCardDetails[] | undefined;

    if (requestEvent) {
      let sid =
        requestEvent?.locals?.sid ??
        getCookie(requestEvent?.nativeEvent, Cookie.SessionId);

      if (!sid) {
        throw redirect("/gifts/login");
      } else {
        const gifts = await getAllGifts();

        const detailedGifts = await Promise.all(
          gifts.map((gift) => getGiftById(gift.id))
        );

        userProfile = await getUserProfile();

        notPaidGifts = detailedGifts
          .filter((conversation) => conversation.status === "COMPLETED")
          .filter(
            (gift) =>
              gift.paymentStatus === "NOT_PAID" ||
              gift.paymentStatus === "PENDING"
          );

        paidGifts = detailedGifts
          .filter((conversation) => conversation.status === "COMPLETED")
          .filter((gift) => gift.paymentStatus === "PAID");
      }
    }

    return { paidGifts, notPaidGifts, userProfile };
  };
