import { A, cache, createAsync, redirect } from "@solidjs/router";
import {
  Accessor,
  createSignal,
  For,
  Match,
  Suspense,
  Switch,
  useTransition,
} from "solid-js";
import { GiftCard } from "~/components/pluto/gift_card";
import {
  getPlutoProfileRouteData,
  PlutoProfileRouteData,
} from "~/server/data/pluto_profile_route_data";
import { formatDateLong } from "~/utils/date";
import { toIndianNumber } from "~/utils/number";
import { ThreeDotLoader } from "~/widgets/button";
import { DottedLoader } from "~/widgets/loader";

const getProfileRouteData$C = cache(getPlutoProfileRouteData, "pluto_profile");

export default function PlutoProfile() {
  const routeData: Accessor<PlutoProfileRouteData | undefined> = createAsync(
    () => getProfileRouteData$C(),
    {
      deferStream: true,
    }
  );

  const [tab, setTab] = createSignal("drafts");
  const [pending, start] = useTransition();

  const updateTab = (side: string) => () => start(() => setTab(side));

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class="flex h-full w-full flex-col overflow-y-auto bg-plutoLoginBgMob bg-cover bg-no-repeat text-white md:w-[800px]">
        <div class="p-4 pb-0 pt-16">
          <h1 class="text-[28px] font-semibold text-white">
            {routeData()?.userProfile?.firstName}{" "}
            {routeData()?.userProfile?.lastName}
          </h1>
          <p class="mb-8 text-medium text-baseSecondaryLight">
            {routeData()?.userProfile?.phoneNumber}
          </p>
          <p class="text-h5 text-white">My gifts</p>
          <div class="border-b border-basePrimaryMedium">
            <nav class="flex gap-x-3">
              <button
                type="button"
                class="w-fit cursor-pointer py-3 text-medium transition-colors duration-300"
                classList={{
                  "text-white border-b-[1px] border-white": tab() === "drafts",
                  "text-baseSecondaryLight": tab() !== "drafts",
                }}
                onClick={updateTab("drafts")}
              >
                Drafts
              </button>
              <button
                type="button"
                class="w-fit cursor-pointer py-3 text-medium transition-colors duration-300"
                classList={{
                  "text-white border-b-[1px] border-white":
                    tab() === "topped-up",
                  "text-baseSecondaryLight": tab() !== "topped-up",
                }}
                onClick={updateTab("topped-up")}
              >
                Topped up
              </button>
              {/* <button
                type="button"
                class="w-fit cursor-pointer py-3 text-medium transition-colors duration-300"
                classList={{
                  "text-white border-b-[1px] border-white":
                    tab() === "received",
                  "text-baseSecondaryLight": tab() !== "received",
                }}
                onClick={updateTab("received")}
              >
                Received
              </button> */}
            </nav>
          </div>
        </div>
        <div class="mx-auto overflow-y-auto" classList={{ pending: pending() }}>
          <Suspense
            fallback={
              <div class="flex w-full items-center justify-center p-8">
                <ThreeDotLoader />
              </div>
            }
          >
            <Switch>
              <Match when={tab() === "drafts"}>
                <For each={routeData()?.notPaidGifts}>
                  {(gift) => {
                    return (
                      <div class="mt-4">
                        <GiftCard
                          giftDetails={() => gift}
                          flipOnButton={true}
                          flipOnCard={true}
                          showActions={true}
                          editPath={`/gifts/${gift.id}/edit`}
                          showHeader={true}
                          generatedOnDate={formatDateLong(
                            new Date(gift.generatedOn)
                          )}
                        />
                      </div>
                    );
                  }}
                </For>
              </Match>
              <Match when={tab() === "topped-up"}>
                <For each={routeData()?.paidGifts}>
                  {(gift) => {
                    return (
                      <div class="mt-4">
                        <GiftCard
                          giftDetails={() => gift}
                          flipOnButton={true}
                          flipOnCard={true}
                          showActions={true}
                          editPath={`/gifts/${gift.id}/edit`}
                          showHeader={true}
                          generatedOnDate={formatDateLong(
                            new Date(gift.generatedOn)
                          )}
                          budget={toIndianNumber(gift.budget)}
                        />
                      </div>
                    );
                  }}
                </For>
              </Match>
              {/* <Match when={tab() === "received"}>
                <div class="text-white">received gifts</div>
              </Match> */}
            </Switch>
          </Suspense>
        </div>
      </div>
    </Suspense>
  );
}
